import { Injectable } from '@angular/core';
import { storageService } from '../storage-service/storage.service';

export type Theme = (typeof ThemeService.THEMES)[number];
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(private storage: storageService) {}

  static THEMES = ['dark', 'light'] as const;

  private theme: Theme = 'dark';
  async loadTheme() {
    const theme = await this.storage.get('theme');

    if (ThemeService.THEMES.includes(theme)) {
      this.setTheme(theme);
    } else {
      const prefersDark = matchMedia('(prefers-color-scheme: dark)').matches;
      this.setTheme(prefersDark ? 'dark' : 'light');
    }
  }
  async setTheme(theme: Theme) {
    this.theme = theme;

    if (theme === 'dark') {
      document.documentElement.classList.add('ion-palette-dark');
    } else {
      document.documentElement.classList.remove('ion-palette-dark');
    }

    await this.storage.set('theme', theme);
  }
  async toggleTheme() {
    const currentThemeIndex = ThemeService.THEMES.indexOf(this.theme);
    let newThemeIndex = (currentThemeIndex + 1) % ThemeService.THEMES.length;

    this.setTheme(ThemeService.THEMES[newThemeIndex]);
  }
  getTheme(): Theme {
    return this.theme;
  }
}
